import { useState } from 'react'
import styled from 'styled-components'
import { screenSizes } from 'shared/styles/theme'
import CheckIcon from '@mui/icons-material/Check';
import { Grid, LinearProgress, CircularProgress, Box, Button } from '@mui/material'
import './mobile_quiz.css'
import Backdrop from '@mui/material/Backdrop';
import './mobile_quiz.css'
import Review_pan from './Review_pan'

function createMarkup(str:string) {
    return {__html: str};
}

const Mobile_Quiz = (props: any) => {
    const { 
        showResult, 
        click_prev, 
        model_name, 
        progress, 
        visible, 
        question, 
        choices, 
        click_model_a_image, 
        model_obj, 
        str_arr, 
        circleprogress, 
        isCircleLoading, 
        CircularProgressWithLabel, 
        product_name, 
        image_url, 
        product_url,
        click_model_a,
        iframe_link,
        onAnswerSelected,
        background_image,
        result_str,
        click_save_example_from_this_result 
    } = props

    return (
        <>
            <div id="root2" style={showResult && isCircleLoading === false ? {backgroundColor: '#fafafa'} : {}}>
                <div className="total_container_m">
                    <div className="content_div_m">
                        <div className={showResult || isCircleLoading === true ? "content_head_div_result_m" : "content_head_div_m"}>
                            <div className={showResult || isCircleLoading === true ? "content_head_result_m" : "content_head_m"}>
                                {
                                    // showResult || isCircleLoading === true ? 
                                    showResult && isCircleLoading === false ? 
                                    <>
                                        {/* <div style={{textAlign: 'center', padding: '0px 15px'}}> */}
                                        <div style={{textAlign: 'center', padding: '0px 0px'}}>
                                        <div>
                                            <span style={{fontSize: "22px", fontWeight: 600, color: "#0093fff0"}}>Productwiz</span>
                                        </div>
                                        </div>
                                    </>
                                    :
                                    isCircleLoading === true ? <></>
                                    :
                                    <div className="left_arrow_div" onClick={() => click_prev()}>
                                        <svg width="24" height="24" viewBox="0 0 24 24">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3.414 10.657L3.071 11 2 12.071l1.414 1.414 6.364 6.364a1 1 0 101.414-1.414L5.757 13h14.314a1 1 0 100-2H5.9l5.293-5.293a1 1 0 00-1.414-1.414l-6.364 6.364z"
                                        ></path>
                                        </svg>
                                    </div>
                                }
                                
                                {
                                    showResult || isCircleLoading === true ?
                                    <></>
                                    :
                                    <div className="progress_div">
                                        <CustomLinearProgress variant="determinate" value={progress} color="primary" />
                                    </div>
                                }
                                
                            </div>
                        </div>
                        {
                        isCircleLoading === true ?
                        <>
                            <div className={visible ? 'content_quiz_div_m quiz_div_fadeIn_m' : 'content_quiz_div_m quiz_div_fadeOut_m'}>
                            <CustomWrapperItems>
                                <div className="content_quiz_container_m">
                                <CustomGrid container spacing={0} className="container_grid_m">
                                    <CustomGrid item sm={12} className="circle_loading_main_grid_m">
                                    <CircularProgressWithLabel value={circleprogress} size={250} text_size="h2" />
                                    </CustomGrid>
                                    <CustomGrid container spacing={0} className="circle_loading_text_div_m">
                                    {/* Finding the perfect {product_name} specifically for you */}
                                    {
                                        circleprogress < 25  ? `Finding the perfect ` + product_name + ` specifically for you ...` :
                                        circleprogress >=25 && circleprogress < 50 ? "Analyzing 137 options based on your preferences ..." :
                                        circleprogress >=50 && circleprogress < 75 ? "Finding the top rated models ..." :
                                        circleprogress >=75 ? "Sorting top deals and discounts ..." : <></>
                                    }
                                    </CustomGrid>
                                </CustomGrid>
                                </div>
                            </CustomWrapperItems>
                            </div>
                        </>
                        :
                        <>
                        {/* <div className="content_quiz_div" > */}
                        <div className={visible ? 'content_quiz_div_m quiz_div_fadeIn_m' : 'content_quiz_div_m quiz_div_fadeOut_m'}>
                            {
                            showResult ? 
                            <>
                            <div style={{textAlign: "center", padding: "20px 0px 0px", borderTop: "1px solid #c5c2c2ba"}} className={"result_div_font_m"}>
                            {/* <div className="content_quiz_container_m"> */}
                                <CustomGrid container spacing={0} className="container_grid_m">
                                    <CustomGrid item sm={12} className="main_grid_m">
                                        {/* <h3>{model_name}</h3> */}
                                        <h1 style={{marginTop: '0px', marginBottom: '10px', fontSize: '26px', color: '#000000de', fontFamily: 'RobotoBold'}}>
                                            Your Match is Complete
                                        </h1>
                                    </CustomGrid>
                                    </CustomGrid>

                                    <CustomGrid container spacing={0} className="container_grid_m">
                                        <CustomGrid item sm={12} className="main_grid_m">
                                            {/* <h3 style={{marginTop: '0'}}>{model_name}</h3> */}
                                            <div style={{marginTop: '0', marginBottom: '20px', color: "#3b3b3b", fontSize: '15px', fontWeight: 'normal', lineHeight: '1.4em'}}>
                                            {/* {model_name}! Based on your preferences we have found the perfect {product_name} for you. */}
                                            Based on your preferences we have found the perfect {product_name} for you.
                                            </div>
                                        </CustomGrid>
                                
                                    </CustomGrid>

                                    <CustomGrid container spacing={0} className="container_grid_m result_container_grid_m">
                                        <CustomGrid item sm={12} className="main_grid_m image_title_m">
                                            {/* <h3 style={{marginTop: '0'}}>{model_name}</h3> */}
                                            <h3>
                                            {model_name}
                                            </h3>
                                        </CustomGrid>
                                        <CustomGrid item sm={12} className="result_div_m">
                                            <Page>
                                            <section className="imginner">
                                                <aside className="imageupload">
                                                <div
                                                    className="imageuploadinner"
                                                    // onClick={() => {
                                                    // click_model_a_image(product_url, model_obj)
                                                    // window.open(`${product_url}`, '_blank')
                                                    // }}
                                                >
                                                    <img
                                                    src={image_url}
                                                    onLoad={() => {
                                                        URL.revokeObjectURL(image_url)
                                                    }}
                                                    />
                                                </div>
                                                </aside>
                                                <div className="dropzoneOuter"></div>
                                            </section>
                                            </Page>
                                        </CustomGrid>
                                        <CustomGrid item sm={12} className="analyze_result_div">
                                            {result_str}
                                            {/* <div className="learnmore_div_m" style={{padding: '20px 10px'}}>
                                                <a 
                                                    href={product_url} 
                                                    target="_blank" 
                                                    onClick={() => click_model_a(product_url, model_obj)}
                                                    style={{
                                                        color: '#0f99fe', 
                                                        fontSize: '15px', 
                                                        fontWeight: '600', 
                                                        textDecoration: 'none', 
                                                        borderBottom: '2px solid #0f99fe'
                                                    }}
                                                >
                                                    <span>
                                                        Learn More 
                                                        <span style={{fontSize: '12px'}}>
                                                            &gt;&gt;
                                                        </span>
                                                    </span>
                                                </a>
                                            </div> */}
                                        </CustomGrid>
                                    </CustomGrid>

                                    <div className='save_example_btn_div'>
                                        <CustomDiv 
                                        className='save_example_btn' 
                                        onClick={() => {
                                            click_save_example_from_this_result(product_url)
                                            // window.open(`${product_url}`, '_blank')
                                        }}
                                        >
                                        Save the example from this result
                                        </CustomDiv>
                                        
                                    </div>

                                    {/* <CustomDiv className="reivew_pan_container_div">
                                        <Review_pan product_name={product_name} />
                                    </CustomDiv> */}

                                    {
                                        // iframe_link === undefined || iframe_link === "" || iframe_link.trim() === "" 
                                        // ? <></>
                                        // : 
                                        // <>
                                        //     <br />
                                        //     <br />
                                        //     <CustomGrid container spacing={0} className="container_grid_m">
                                        //         <CustomGrid item sm={12} className="main_grid_m">
                                        //             {/* <h3 style={{marginTop: '0'}}>{model_name}</h3> */}
                                        //             <div style={{marginTop: '0', fontSize: '26px', fontWeight: 'normal', lineHeight: '1.2em', color: '#3b3b3b'}}>
                                        //             Check out the ratings for the top {product_name}
                                        //             </div>
                                        //         </CustomGrid>
                                        //     </CustomGrid>
                                        //     <br />
                                        //     <CustomGrid container spacing={0} className={'iframe_container_grid_m'}>
                                        //         <CustomGrid item xs={12} className={'iframe_div1'}>
                                        //             {/* <div style={{ width: '100%', height: '450px', display: 'flex', justifyContent: 'center'}}> */}
                                        //             <div style={{ width: '100%', height: '150vh', display: 'flex', justifyContent: 'center'}}>
                                        //                 <iframe
                                        //                 src={iframe_link}
                                        //                 // height="450"
                                        //                 width="100%"
                                        //                 title="quiz iframe"
                                        //                 // style={{ borderStyle: 'ridge' }}
                                        //                 style={{ borderStyle: 'ridge', height: '150vh' }}
                                        //                 ></iframe>
                                        //             </div>
                                        //         </CustomGrid>
                                        //     </CustomGrid>
                                        // </>
                                    }
                                    
                                </div>
                            </>
                            : 
                            <>
                                <div className="content_quiz_container_m">
                                <div className="quiz_question_div_m">
                                    {question}
                                </div>
                                <div className="quiz_answers_div_m">
                                    <div className="quiz_answers_container_m">
                                    {choices.length > 0 &&
                                        choices.map((answer: any, index: number) => (
                                        <div 
                                            key={index} 
                                            className="quiz_answers_button_div_m" 
                                            onClick={() => onAnswerSelected(question, answer, index)}
                                        >
                                            {/* <span className="answer_icon_span_m"></span> */}
                                            <span className="answer_text_span_m">
                                            {answer.choice}
                                            </span>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                                </div>
                            </>
                            }
                            
                        </div>
                        </>
                        }
                        
                    </div>
                    <div className="foot_img_div_m">
                        {/* <div className="house_img_m"></div> */}
                        <div className="house_img" style={{backgroundImage: `url(${background_image})`, backgroundSize: 'contain'}}></div>
                        <div className="foot_links_m">
                        <div className="foot_links_container_m">
                            <span>© productwiz</span>
                            <a href="">Licenses & Disclosures</a>
                            <a href="">Privacy</a>
                            <a href="">Contact</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    );
};


const CustomDiv = styled.div`
  
  @media (max-width: ${screenSizes.mediaXLL}px) {
    &.container_grid {
      padding: 0px 20px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    &.container_grid {
      padding: 0px 30px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    &.container_grid {
      padding: 0px 40px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    &.container_grid {
      padding: 0px 50px !important;
    }
  }

  &.reivew_pan_container_div {
    margin: 40px auto;
    padding: 5px 0px 15px !important;
    display: flex!important;
    flex-direction: column!important;
    justify-content: center;
    align-content: center;
    border: 1px solid #c5c2c2ba;
    background-color: white;
  }

`

const CustomA = styled.a`
    text-decoration: none;
    // width: 100%;

`

const CustomButton = styled(Button)`
`

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 5px;

  img {
    width: 120px;
    height: 20px;
    margin-top: 5px;
  }

  ${CustomButton} {
    width: 133px;
    padding: 10px;
    cursor: pointer;
    font-family: sans-serif;
    font-weight: 600;
    line-height: 1.33;
    font-size: 15px!important;
    letter-spacing: 0.03em!important;
    color: white!important;
    background-color: #f2a842!important;
    border: 1px solid #f09637!important;
    
    &:hover {
        border: 1px solid #f2a842!important;
        background-color: #f09637!important;
    }  
  }
`

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
    background: #fff;
`
const CustomGrid = styled(Grid)`
  
    &.result_container_grid_m {
        padding: 5px 0px 15px !important;
        display: flex!important;
        flex-direction: column!important;
        justify-content: center;
        align-content: center;
        border: 1px solid #c5c2c2ba;
        background-color: white;
    }

    &.iframe_container_grid_m {
        // padding: 5px 0px 15px !important;
        display: flex!important;
        // flex-direction: column!important;
        justify-content: center;
        align-content: center;
        border: 1px solid #c5c2c2ba;
        background-color: white;
      }

    &.container_grid_m {
        display: flex!important;
        justify-content: center!important;
        padding: 0px 10px!important;
    }
  .main_grid_m {
    // background: linear-gradient(90.04deg,#0d5ff5 0.03%,#c0e5ff 99.96%)!important;
    // background: linear-gradient(145deg,#5c8fed 0.03%,#d1f0f7 99.96%)!important;
    // padding: 0px 50px 0px!important;
  }

  .image_title_m h3 {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    padding: 10px 30px;
    padding-top: 40px;
    color: #000000;
  }

  .circle_loading_main_grid_m {
    padding: 50px 50px 0px!important;
    display: flex;
    justify-content: center;
  }

  .circle_loading_text_div_m {
    display: flex!important;
    justify-content: center!important;
    font-size: 22px!important;
    font-weight: 500!important;
    padding: 20px 0!important;
    text-align:center!important;
  }

  .result_div_m {
    // border-right: 1px solid gray!important;
    padding: 10px!important;
  }
  .analyze_result_div {
    white-space: pre-wrap !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    text-align: left !important;
    line-height: 24px;
    color: #000000;
    letter-spacing: 0;
  }
`
const CustomLinearProgress = styled(LinearProgress)`
  &.css-eglki6-MuiLinearProgress-root {
    background-color: #f0f1f1a8;
    height: 18px;
  }
  &.css-7p5u54 {
    background-color: #f0f1f1a8;
    height: 18px;
  }
`

const Page = styled.div`
  color: #fff;
  // border: 1px solid rgba(0,0,0,0.05);
  // border-radius: 24px;
  // width: fit-content;
  // min-width: 400px;
  // min-height: 360px;
  // margin-left: 20%;
  display: flex;
  justify-content: Center;
  align-items: Center;
  // @media (max-width: 1200px) {
  //   margin: auto;
  //   margin-bottom: 30px;
  // }
  .imageupload {
    display: flex;
    justify-content: Center;
    // background: #b4fbf8c7;
    // padding: 40px 130px;
    border-radius: 10px;
    position: relative;
    .imageuploadinner {
      position: relative;
      width: 230px;
      height: 230px;
    //   border: 1px solid gray;
    // border-radius: 5px;
      // border-radius: 50%;
      overflow: hidden;
    //   cursor: pointer;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  
`

export default Mobile_Quiz