import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import styled1 from 'styled-components'
import { Grid, Divider, Box } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import history from 'modules/app/components/history'
import jwt_decode from 'jwt-decode'
import { read_all_quiz, read_quiz_by_id } from 'modules/api/market-api'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import MobileHeader from './mobile_page/MobileHeader'
import GroupIcon from '@mui/icons-material/Group'
import AdminHeader from 'shared/AdminHeader'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import Container from '@mui/material/Container';
import Test from 'modules/test'
import Toolbar from '@mui/material/Toolbar';

Chart.register(CategoryScale)

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 35,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

const BorderLinearProgress_Model = styled(LinearProgress)(({ theme }) => ({
  height: 35,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#6bad1f' : '#308fe8',
  },
}))

const Admin_Quiz_Analyze = (props: any) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isFirstLoading, setIsFirstLoading] = React.useState<boolean>(false)

  const [products, setProducts] = React.useState<any>([])
  const [best_url, setBestUrl] = React.useState<string>('')
  const [best_url_visited_users, setBestUrlVisitedUsers] = React.useState<any>([])
  const [quiz, setQuiz] = React.useState<any>([])
  const [models, setModels] = React.useState<any>([])
  const [total_model_count, setTotalModelCount] = React.useState<number>(0)
  const [total_model_click_count, setTotalModelClickCount] = React.useState<number>(0)
  const [total_visitors, setTotalVisitors] = React.useState<number>(0)
  const [state_bar, setStateBar] = React.useState<any>({
    labels: ['Frist Screener Visitors', 'Quiz Visitors'],
    datasets: [
      {
        label: '',
        // backgroundColor: ['rgba(75, 192, 192, 0.5)', 'rgba(255, 205, 86, 0.5)'],
        backgroundColor: ['rgba(75, 192, 192, 0.5)', 'rgba(26, 144, 255, 0.5)'],
        // hoverBackgroundColor: ['rgba(75, 192, 192, 0.8)', 'rgba(255, 205, 86, 0.8)'],
        hoverBackgroundColor: ['rgba(75, 192, 192, 0.8)', 'rgba(26, 144, 255, 0.8)'],
        data: [0, 0],
      },
    ],
  })

  const [state_pie, setStatePie] = React.useState<any>({
    labels: ['Quiz Visitors', 'Frist Screener Visitors'],
    datasets: [
      {
        label: 'Rainfall',
        // backgroundColor: ['rgba(255, 205, 86, 0.5)', 'rgba(75, 192, 192, 0.5)'],
        backgroundColor: ['rgba(26, 144, 255, 0.5)', 'rgba(75, 192, 192, 0.5)'],
        hoverBackgroundColor: ['rgba(26, 144, 255, 0.8)', 'rgba(75, 192, 192, 0.8)'],
        data: [0, 0],
      },
    ],
  })

  useEffect(() => {
    setIsFirstLoading(true)

    if (localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null) {
      const decoded: any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))

      if (decoded.id == localStorage.getItem('userDbId')) {
        (async () => {
          const result: any = await read_all_quiz()
          if (result.status === 'success') {
            let products = result.result
            products = products.map((product: any, index: number) => {
              product = Object.assign(product, { active: false })
              return product
            })
            setProducts(products)

            setIsFirstLoading(false)
          } else if (result.status === 'error') {
            setIsFirstLoading(false)

            history.push('/admin/signin')
          }
        })()
      } else {
        setIsFirstLoading(false)

        history.push(`/admin/signin`)
      }
    } else {
      setIsFirstLoading(false)

      history.push(`/admin/signin`)
    }
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        let active_product_index = products.findIndex((ele: any) => ele.active === true)
        if (active_product_index !== -1) {
          let result = await read_quiz_by_id(products[active_product_index]._id)
          let best_url = result.best_url
          if (best_url !== undefined) {
            setBestUrl(best_url.best_url)
            setBestUrlVisitedUsers(best_url.users)
          } else {
            setBestUrl('')
            setBestUrlVisitedUsers([])
          }
          let quiz = result.quiz
          let models = result.models

          quiz = quiz.map((ele: any, index: number) => {
            ele.choices = ele.choices.map((ele1: any, index: number) => {
              let temp_count = 0
              if (ele.users !== undefined) {
                for (let i = 0; i < ele.users.length; i++) {
                  if (ele.users[i].choice === ele1.choice) temp_count++
                }
              } else {
              }

              ele1 = Object.assign(ele1, { count: temp_count })
              return ele1
            })
            return ele
          })

          setQuiz(quiz)

          let users = result.users
          models = models.map((ele: any, index: number) => {
            let temp_count = 0
            let temp_click_count = 0
            if (users !== undefined) {
              for (let i = 0; i < users.length; i++) {
                if (users[i].model_name === ele.model_name) {
                  temp_count++
                  if (users[i].link.links_on_image.length !== 0 || users[i].link.links_on_text.length !== 0) {
                    temp_click_count++
                  }
                }
              }
            } else {
            }

            ele = Object.assign(ele, { count: temp_count })
            ele = Object.assign(ele, { click_count: temp_click_count })

            return ele
          })

          let total = 0
          let total_click = 0
          for (let i = 0; i < models.length; i++) {
            if (models[i].count !== undefined) {
              total = total + models[i].count
            } else {
            }

            if (models[i].click_count !== undefined) {
              total_click = total_click + models[i].click_count
            } else {
            }
          }

          setModels(models)
          setTotalModelCount(total)
          setTotalModelClickCount(total_click)

          let users_arr: any = []
          quiz.map((ele: any, index: number) => {
            let temp_user_arr = []
            if (ele.users !== undefined) {
              temp_user_arr = ele.users.map((ele1: any, index: number) => {
                return {
                  user_name: ele1.user_name,
                  user_info: ele1.user_info,
                }
              })
            }

            users_arr = users_arr.concat(temp_user_arr)
          })

          users_arr = users_arr.filter(
            (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.user_name === value.user_name)
          )

          setTotalVisitors(users_arr.length)
        } else if (active_product_index === -1) {
        }
        setIsLoading(false)
      } catch (e) {
        // console.log(e)
        history.push(`/admin/signin`)
      }
    })()
  }, [products])

  useEffect(() => {
    setStateBar((prev: any) => {
      let next = prev

      next.datasets[0].data[0] = best_url_visited_users.length
      next.datasets[0].data[1] = total_visitors

      return next
    })

    setStatePie((prev: any) => {
      let next = prev

      next.datasets[0].data[0] = total_visitors
      next.datasets[0].data[1] = best_url_visited_users.length

      return next
    })
  }, [best_url_visited_users, total_visitors])

  const clicked_product = (active: boolean, index: number, product_name: string, product_id: string) => {
    if (active === true) {
    } else if (active === false) {
      setProducts((prev: any[]) => {
        let next = [...prev]

        let prev_active_index = next.findIndex((ele) => ele.active === true)

        if (prev_active_index !== -1) {
          next[prev_active_index].active = false
        }

        next[index].active = true

        return next
      })
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {/* <AdminHeader /> */}
          <Test />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar sx={{minHeight: '48px!important'}}/>
            <Container sx={{ mt: 0, mb: 0, pl: '0!important', pr: '0!important', maxWidth: '100%!important' }}>
              <CustomGrid container spacing={0} className={"pt-0"}>
                <CustomGrid item xs={12}  className={"pt-0"}>
                  <Box sx={{ typography: 'body1' }}>
                    <div>
                      {isFirstLoading === true ? (
                        <>
                          <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isFirstLoading}
                            // onClick={handleClose}
                          >
                            <CircularProgress color="inherit" />
                            <p>&nbsp;&nbsp; Loading Data ...</p>
                          </Backdrop>
                        </>
                      ) : (
                        <></>
                      )}
                      {isLoading === true ? (
                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={isLoading}
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit" />
                          <p>&nbsp;&nbsp; Loading ...</p>
                        </Backdrop>
                      ) : (
                        <></>
                      )}
                      {/* <AdminHeader /> */}
                      <CustomGrid container spacing={0}>
                        <CustomGrid item xs={12} className={'grid_header'}>
                          <MobileHeader products={products} clicked_product={clicked_product} />
                        </CustomGrid>

                        <CustomGrid item xs={12} md={2} className={'grid_list'}>
                          <CustomDiv className={'list_div'}>
                            {products.length > 0 &&
                              products.map((product: any, index: number) => (
                                <CustomDiv key={index} className={'btn_div'}>
                                  <CustomDiv
                                    className={product.active === true ? 'active_list_item btn_style' : 'btn_style'}
                                    onClick={() => clicked_product(product.active, index, product.product_name, product._id)}
                                  >
                                    <CustomDiv className={'txt_div'}>{product.product_name}</CustomDiv>
                                  </CustomDiv>
                                </CustomDiv>
                              ))}
                          </CustomDiv>
                        </CustomGrid>
                        <CustomGrid item xs={12} md={10} className={'content_div_whole'}>
                          <CustomDiv className={quiz.length > 0 ? 'visitor_template' : ''}>
                            <CustomDiv className={'total_visitors'}>
                              {quiz.length > 0 && (
                                <>
                                  <GroupIcon />
                                  &nbsp;Total Page Visitors: {best_url_visited_users.length + total_visitors}
                                </>
                              )}
                            </CustomDiv>
                            <CustomDiv className={'chart_div'}>
                              {quiz.length > 0 && (
                                <>
                                  <CustomDiv className={'chart_div1'}>
                                    <Bar
                                      data={state_bar}
                                      options={{
                                        plugins: {
                                          legend: {
                                            display: false,
                                          },
                                          tooltip: {
                                            displayColors: false,
                                          },
                                        },
                                      }}
                                    />
                                  </CustomDiv>
                                  <CustomDiv className={'chart_div2'}>
                                    <Pie
                                      data={state_pie}
                                      options={{
                                        plugins: {
                                          tooltip: {
                                            displayColors: false,
                                          },
                                        },
                                      }}
                                    />
                                  </CustomDiv>
                                </>
                              )}
                            </CustomDiv>
                          </CustomDiv>
                          <CustomDiv className={quiz.length > 0 ? 'visitor_template' : ''}>
                            <CustomDiv className={'total_visitors'}>
                              {quiz.length > 0 && (
                                <>
                                  <GroupIcon />
                                  &nbsp;First Screener Visitors: {best_url_visited_users.length}
                                </>
                              )}
                            </CustomDiv>
                            <CustomDiv className={'content_div_best_url'}>
                              {quiz.length > 0 && (
                                <>
                                  <CustomGrid container spacing={0} className="container_grid result_container_grid">
                                    <CustomGrid item sm={12} className="analyze_result_div">
                                      <CustomDiv className="answer_div_best_url">
                                        <CustomDiv className={'best_url_link_context'}>First Screener Url:</CustomDiv> &nbsp;{best_url}
                                      </CustomDiv>
                                    </CustomGrid>
                                  </CustomGrid>
                                </>
                              )}
                            </CustomDiv>
                          </CustomDiv>
                          <CustomDiv className={quiz.length > 0 ? 'visitor_template' : ''}>
                            <CustomDiv className={'total_visitors'}>
                              {quiz.length > 0 && (
                                <>
                                  <GroupIcon />
                                  &nbsp;Quiz Visitors: {total_visitors}
                                </>
                              )}
                            </CustomDiv>
                            <CustomDiv className={'content_div'}>
                              {quiz.length > 0 &&
                                quiz.map((ele: any, index: number) => {
                                  let total = 0
                                  for (let i = 0; i < ele.choices.length; i++) {
                                    total = total + ele.choices[i].count
                                  }
                                  return (
                                    <div key={index}>
                                      <CustomGrid container spacing={0} className="container_grid">
                                        <CustomGrid item sm={12} className="main_grid">
                                          {/* <h3>Result</h3> */}
                                          <h3>{ele.question}</h3>
                                        </CustomGrid>
                                      </CustomGrid>

                                      <CustomGrid container spacing={0} className="container_grid result_container_grid">
                                        <CustomGrid item sm={12} className="progress_div">
                                          {total !== 0 ? 'total answers: ' + total : 'total answers: 0'}
                                        </CustomGrid>
                                        <CustomGrid item sm={12} className="analyze_result_div">
                                          {ele.choices.length > 0 &&
                                            ele.choices.map((ele1: any, index1: number) => {
                                              return (
                                                <div key={index1}>
                                                  <CustomDiv className="answer_div">
                                                    <div>
                                                      {ele1.choice}: &nbsp;{ele1.count}
                                                    </div>
                                                    <div>{total != 0 ? ((ele1.count / total) * 100).toFixed(2) : 0}%</div>
                                                  </CustomDiv>
                                                  <BorderLinearProgress variant="determinate" value={total != 0 ? (ele1.count / total) * 100 : 0} />
                                                  <br />
                                                </div>
                                              )
                                            })}
                                        </CustomGrid>
                                      </CustomGrid>
                                    </div>
                                  )
                                })}

                              {quiz.length > 0 && <Divider />}

                              <CustomGrid container spacing={0} className="container_grid">
                                <CustomGrid container item sm={6}>
                                  <CustomGrid item sm={12} className="main_grid">
                                    <h3 style={{ color: '#6bad1f' }}>
                                      {models.length > 0 &&
                                        (total_model_count !== 0 ? 'Total model results: ' + total_model_count : 'Total model results: 0')}
                                    </h3>
                                  </CustomGrid>
                                  <CustomGrid item sm={12}>
                                    {models.length > 0 &&
                                      models.map((ele: any, index: number) => {
                                        return (
                                          <div key={index}>
                                            <CustomDiv className="answer_div">
                                              <div>
                                                {ele.model_name}: &nbsp;{ele.count !== undefined ? ele.count : 0}
                                              </div>
                                              <div>
                                                {total_model_count != 0 && ele.count !== undefined ? ((ele.count / total_model_count) * 100).toFixed(2) : 0}
                                                %
                                              </div>
                                            </CustomDiv>
                                            <BorderLinearProgress_Model
                                              variant="determinate"
                                              value={total_model_count != 0 && ele.count !== undefined ? (ele.count / total_model_count) * 100 : 0}
                                            />
                                            <br />
                                          </div>
                                        )
                                      })}
                                  </CustomGrid>
                                </CustomGrid>
                                <CustomGrid item sm={6}>
                                  <CustomGrid item sm={12} className="main_grid">
                                    <h3 style={{ color: '#6bad1f' }}>
                                      {models.length > 0 &&
                                        (total_model_click_count !== 0
                                          ? 'Total click counts of all models: ' + total_model_click_count
                                          : 'Total click counts of all models: 0')}
                                    </h3>
                                  </CustomGrid>
                                  <CustomGrid item sm={12}>
                                    {models.length > 0 &&
                                      models.map((ele: any, index: number) => {
                                        return (
                                          <div key={index}>
                                            <CustomDiv className="answer_div">
                                              <div>
                                                {ele.model_name}: &nbsp;{ele.click_count !== undefined ? ele.click_count : 0}
                                              </div>
                                              <div>
                                                {total_model_click_count != 0 && ele.click_count !== undefined
                                                  ? ((ele.click_count / total_model_click_count) * 100).toFixed(2)
                                                  : 0}
                                                %
                                              </div>
                                            </CustomDiv>
                                            <BorderLinearProgress_Model
                                              variant="determinate"
                                              value={
                                                total_model_click_count != 0 && ele.click_count !== undefined
                                                  ? (ele.click_count / total_model_click_count) * 100
                                                  : 0
                                              }
                                            />
                                            <br />
                                          </div>
                                        )
                                      })}
                                  </CustomGrid>
                                </CustomGrid>
                              </CustomGrid>
                            </CustomDiv>
                          </CustomDiv>
                        </CustomGrid>
                      </CustomGrid>
                    </div>
                  </Box>
                </CustomGrid>
              </CustomGrid>
            </Container>
          </Box>
        </Box>
     
    </>
  )
}

const CustomDiv = styled1.div`
  &.input_div {
    display: flex;
    justify-content: center;
    padding-bottom: 10px!important;
  }
  &.answer_div {
    display: flex;
    justify-content: space-between;
  }
  &.answer_div_best_url {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }
  &.best_url_link_context {
    border-radius: 4px;
    padding: 5px;
    font-weight: 600;
  }
  &.txt_div {
    flex: 1 1 auto!important;
  }
  &.list_div {
    height: 85vh;
    overflow: auto;
    border-right: 1px solid darkgray!important;
    padding-right: 5px!important;
    padding-top: 5px!important;
  }
  &.content_div {
    // height: 85vh;
    overflow: auto;
    padding-left: 5px!important;
    padding-right: 5px!important;
    padding-top: 5px!important;
  }

  &.content_div_best_url {
    // height: 85vh;
    overflow: auto;
    padding-left: 5px!important;
    padding-right: 5px!important;
    padding-top: 5px!important;
  }

  &.btn_style {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
  }

  &.btn_div {
    border-bottom: 1px solid #c1bbbb22;
    margin: 2px 0px;
    transition: all 350ms ease-in-out;
  }
  &.btn_div:hover {
    background-color: #00000022!important;
  }

  &.active_list_item {
      background-color: #1e947e;
      color: white;
      transition: all 300ms ease-in-out;
  }
  
  &.visitor_template {
    border: 1px solid #8080806b;
    border-radius: 5px;
    padding: 15px 15px;
    margin: 0px 0px 20px;
  }

  &.total_visitors {
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    color: #1a90ffd4;
    margin-bottom: 10px;
  }
  &.chart_div {
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  &.chart_div1 {
    display: flex;
    margin: auto;
    width: 25vw;
    justify-content: space-around;
    align-items: center;
  }
  &.chart_div2 {
    display: flex;
    margin: auto;
    width: 15vw;
    justify-content: space-around;
    align-items: center;
  }
`

const CustomGrid = styled1(Grid)`
  background-color: white!important;
  padding: 2px;
  margin: 0px!important;

  &.content_div_whole {
    height: 85vh;
    overflow: auto;
  }
  &.grid_header {
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none!important;
    }
  }
  &.grid_list {
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none!important;
    }
  }
  .header_logo{
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
   
  }

  .grid-header-right {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }

  .pt-0 {
    padding-top: 0px!important;
  }

  .grid_main{
    display: flex;
    justify-content: start;
  }

  .main_grid {
    padding: 0px 50px 0px!important;
    display: flex;
    justify-content: center;
  }
  .circle_loading_main_grid {
    padding: 50px 50px 0px!important;
    display: flex;
    justify-content: center;
  }

  .circle_loading_text_div {
    display: flex!important;
    justify-content: center!important;
    font-size: 22px!important;
    font-weight: 500!important;
    padding: 20px 0!important;
    text-align:center!important;
  }

  .progress_div {
    display: flex;
    justify-content: center;
  }

  .analyze_result_div {
    width: 100%;
  }
`

const HeaderContainer = styled1.div`
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`

const BoldSpan = styled1.span`
  font-weight: 700;
`

export default Admin_Quiz_Analyze
