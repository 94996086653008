import * as React from 'react';
import { Grid } from '@mui/material'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { regenerate_question } from 'modules/api/market-api';
import history from 'modules/app/components/history'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function RegenQuestionDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
  const [ num_of_quizs, setNumOfQuizs ] = React.useState<number>(8);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const handleClose = () => {
    setNumOfQuizs(8)
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)

        let update_result = await regenerate_question(props.products[props.products.findIndex((ele: any) => ele.active === true)]._id, props.products[props.products.findIndex((ele: any) => ele.active === true)].product_name, num_of_quizs, props.products[props.products.findIndex((ele: any) => ele.active === true)].log_file_name)

        if ( update_result.status === 'success' ) {
          props.setProducts((prev: any[]) => {
            let next = [...prev]
            next[props.products.findIndex((ele: any) => ele.active === true)].quiz = update_result.quiz
            // next[props.products.findIndex((ele: any) => ele.active === true)].quiz = update_result.update_result.quiz
            return next
          })
          setIsLoading(false)
          props.setOpen(false);
        } else if ( update_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
          history.push('/admin/signin')
        }
        
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleNumerOfQuizsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumOfQuizs(parseInt(e.target.value))    
  }

  return (
    <>
      <div>
        <Dialog open={open} onClose={handleClose} >
          {
            isLoading === true ?
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
                <p>&nbsp;&nbsp; Generating ...</p>
            </Backdrop>
            : <></>
          }
          <DialogTitle>Regenerate Question and Choices</DialogTitle>
          <DialogContent>
            <CustomDiv1 className={"num_quiz_div"}>
              <DialogContentText>
                Number of Questions:
              </DialogContentText>
              <input
                name="num_of_quiz"
                type="number"
                placeholder=""
                min="1"
                value={num_of_quizs}
                onChange={handleNumerOfQuizsChange}
              />
            </CustomDiv1>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Generate</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>    
  );
}

const CustomGrid = styled(Grid)`
    &.mui_grid_flex {
        display: flex!important;
    }
    &.mui_grid_item_end {
        justify-content: end;
        align-items: center;
    }
    &.mui_grid_padding_left {
      padding-left: 15px!important;
    }
    &.mui_grid_add {
      justify-content: center!important;
      margin: 15px 0 0!important;
    }
    
`

const CustomDiv1 = styled.div`
&.num_quiz_div {
  display: flex!important;
  flex-direction: row!important;
  align-items: center!important;
  input {
    margin-left: 10px;
    padding: 4px;
    font-size: 18px;
    line-height: 30px;
    border: 1px solid #c4c4c4!important;
    border-radius: 5px!important;
    width:50px!important;
  }
  input:hover {
    border-color: black!important;
  }
  input:focus-visible {
    border: 2px solid #1976d2!important;
    outline: none!important;
  }
  
}   
`

const CustomCancelIcon = styled(RemoveCircleOutlineIcon)`
    cursor: pointer;
    &.delete_icon {
        margin-left: 10px;
    }
    &.delete_icon: hover {
        color: #ff3100cf!important;
        margin-left: 10px;
    }
`
const CustomAddIcon = styled(AddCircleOutlineIcon)`
    cursor: pointer;
    &.delete_icon: hover {
        color: #0070ff!important;
    }
`