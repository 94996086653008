import * as React from 'react';
import { Grid } from '@mui/material'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_quiz_quizs_by_id } from 'modules/api/market-api';
import history from 'modules/app/components/history'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function AddOneQuizDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
  const [ edited_question, setEditedQuestion ] = React.useState<string>("");
  const [ edited_choices, setEditedChoices ] = React.useState<any>([])

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const handleClose = () => {
    // props.setQuizIndex(-1)
    setEditedQuestion("")
    setEditedChoices([])
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        let temp_edited_question = edited_question.trim()
        let temp_edited_choices = JSON.parse(JSON.stringify(edited_choices));

        temp_edited_choices = temp_edited_choices.filter((choice:any) => {
          return choice.choice.trim().length != 0
        })

        temp_edited_choices = temp_edited_choices.map((choice:any, index:number) => {
          // return choice.trim()
          choice.choice = choice.choice.trim()
          return choice
        })

        if ( temp_edited_choices.length === 0 || temp_edited_question.length === 0) {
          // console.log('no "Question" or no "Choices" ... ')
          return
        } else {
          let temp_quiz = JSON.parse(JSON.stringify(props.products[props.products.findIndex((ele: any) => ele.active === true)].quiz))
          // temp_quiz[props.quiz_index].question = temp_edited_question
          // temp_quiz[props.quiz_index].choices = JSON.parse(JSON.stringify(temp_edited_choices))

          temp_quiz.push({
            question: temp_edited_question,
            choices: JSON.parse(JSON.stringify(temp_edited_choices))
          })

          setIsLoading(true)

          let update_result = await update_quiz_quizs_by_id(props.products[props.products.findIndex((ele: any) => ele.active === true)]._id, temp_quiz, props.products[props.products.findIndex((ele: any) => ele.active === true)].log_file_name)

          if ( update_result.status === 'success' ) {
            props.setProducts((prev: any[]) => {
              let next = [...prev]
              next[props.products.findIndex((ele: any) => ele.active === true)].quiz = JSON.parse(JSON.stringify(temp_quiz))
              return next
            })
            setIsLoading(false)
            props.setOpen(false);
          } else if ( update_result.status === 'error' ) {
            setIsLoading(false)
            props.setOpen(false);
            history.push('/admin/signin')
          }
        }
        
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedQuestion(e.target.value)
  }

  const handleChoiceArrChange = (e:any, choice_index:number) => {
    setEditedChoices((prev:any) => {
      let new_choices = [... prev]
      new_choices[choice_index].choice = e.target.value
      return new_choices
    })
  }

  const removeChoice = (e:any, choice_index:number) => {
    setEditedChoices((prev:any) => {
      let new_choices = [... prev]
      new_choices.splice(choice_index, 1)
      return new_choices
    })
  }

  const addChoice = () => {
    setEditedChoices((prev:any) => {
      let new_choices = [... prev]
      // new_choices.push("")
      new_choices.push({
        choice: '',
        count: 0,
      })
      return new_choices
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    
  }

  return (
    <>
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
          {
            isLoading === true ?
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
                <p>&nbsp;&nbsp; Saving Data ...</p>
            </Backdrop>
            : <></>
          }
          <DialogTitle>Add Question and Choices</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Question:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              fullWidth
              value={edited_question}
              onChange={handleQuestionChange}
              onKeyDown={handleKeyDown}
            />
            <p></p>
            <DialogContentText>
              Choices:
            </DialogContentText>
            {
              edited_choices.length > 0 && edited_choices.map((choice:any, index:number) => {
                return (
                  <div key={index}>
                    <CustomDiv1 className={"mui_grid_flex mui_grid_item_end mui_grid_padding_left"}>
                      <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={choice.choice}
                        onChange={(e) => handleChoiceArrChange(e, index)}
                        onKeyDown={handleKeyDown}
                      />
                      <CustomCancelIcon fontSize="small" className={"delete_icon"} onClick={(e) => {removeChoice(e, index)}}/>
                    </CustomDiv1>
                  </div>
                )
              })
            }
              <CustomDiv1 className={"mui_grid_flex mui_grid_item_end mui_grid_add"}>
                <CustomAddIcon fontSize="small" className={"delete_icon"} onClick={(e) => {addChoice()}}/>
              </CustomDiv1>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>    
  );
}

const CustomGrid = styled(Grid)`
    &.mui_grid_flex {
        display: flex!important;
    }
    &.mui_grid_item_end {
        justify-content: end;
        align-items: center;
    }
    &.mui_grid_padding_left {
      padding-left: 15px!important;
    }
    &.mui_grid_add {
      justify-content: center!important;
      margin: 15px 0 0!important;
    }
    
`

const CustomDiv1 = styled.div`
    &.mui_grid_flex {
        display: flex!important;
    }
    &.mui_grid_item_end {
        justify-content: end;
        align-items: center;
    }
    &.mui_grid_padding_left {
      padding-left: 15px!important;
    }
    &.mui_grid_add {
      justify-content: center!important;
      margin: 15px 0 0!important;
    }
    
`

const CustomCancelIcon = styled(RemoveCircleOutlineIcon)`
    cursor: pointer;
    &.delete_icon {
        margin-left: 10px;
    }
    &.delete_icon: hover {
        color: #ff3100cf!important;
        margin-left: 10px;
    }
`
const CustomAddIcon = styled(AddCircleOutlineIcon)`
    cursor: pointer;
    &.delete_icon: hover {
        color: #0070ff!important;
    }
`