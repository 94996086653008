/* global chrome */

import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import styled from 'styled-components'
import { login } from 'modules/api/market-api'
import setAuthToken from 'logic/utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import CustomSnackbar from './CustomSnackbar';
import history from 'modules/app/components/history';
// import Cookies from 'universal-cookie';

// import { chrome } from 'chrome';
// import {createChromeStorageStateHookLocal} from 'use-chrome-storage';

// const SETTINGS_KEY = 'settings';
// const INITIAL_VALUE = {
//     showAvatar: true,
//     showHistory: false,
// };

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        New Quiz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [ email, setEmail ] = React.useState<String>('')
  const [ password, setPassWord ] = React.useState<String>('')
  const [ snackBarShow, setSnackBarShow ] = React.useState<Boolean>(false)
  const iRef = useRef<HTMLIFrameElement>();
  // const useSettingsStore = createChromeStorageStateHookLocal(SETTINGS_KEY, INITIAL_VALUE);
  // const [settings, setSettings, isPersistent, error, isInitialStateResolved] = useSettingsStore();

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassWord(event.target.value)
  }
  
  const submitLogin = () => {
    const userData = {
      email,
      password,
    }
    login(userData)
      .then(result => {
        if ( result.status == 'success' ) {
          const { token } = result.result
          localStorage.setItem('jwtToken', token)

          setAuthToken(token)
          const decoded:any = jwt_decode(token)
          localStorage.setItem('userDbId', decoded.id)
          history.push(`/admin/quiz`)

        } else if ( result.status == 'fail' ) {
          setSnackBarShow(true)
        }
      })
  };

  return (
      <Container  maxWidth="xs" >
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: '#1e947e' }}>
                <KeyOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  // id="email"
                  label="Email Address"
                  // name="email"
                  // autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleChangeEmail}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  value={password}
                  onChange={handleChangePassword}
                />
                <CustomButton
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={submitLogin}
                  className={"signin_btn"}
                >
                Sign In
                </CustomButton>
                <Grid container justifyContent="flex-end">
                {/* <Grid item xs>
                    <Link href="#" variant="body2">
                    Forgot password?
                    </Link>
                </Grid> */}
                {/* <Grid item xs>
                    <Link href="/" variant="body2">
                    Go Home
                    </Link>
                </Grid> */}
                <Grid item>
                    {/* <Link href="/signup" variant="body2"> */}
                    <Link href="" variant="body2">
                    {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
                </Grid>
            </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        <CustomSnackbar show={snackBarShow} setShow={setSnackBarShow}/>
      </Container>
  );
}
const CustomDiv = styled.div`
    &.main_div {
        min-height: 80vh!important;
    }
`
const CustomButton = styled(Button)`
    &.signin_btn {
      background-color: #1e947e!important;
    }
    &.signin_btn:hover {
      background-color: #286b5e!important;
    }
`
