import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_quiz_quizs_by_id } from 'modules/api/market-api';
import history from 'modules/app/components/history'

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function DeleteOneQuizDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
  }, [props.imgArr])

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)

        let temp_quiz = JSON.parse(JSON.stringify(props.products[props.products.findIndex((ele: any) => ele.active === true)].quiz))
        temp_quiz.splice(props.quiz_index, 1)

        const delete_result = await update_quiz_quizs_by_id(props.products[props.products.findIndex((ele: any) => ele.active === true)]._id, temp_quiz,props.products[props.products.findIndex((ele: any) => ele.active === true)].log_file_name)

        if ( delete_result.status === 'success' ) {
            props.setProducts((prev: any[]) => {
              let next = [...prev]
              next[props.products.findIndex((ele: any) => ele.active === true)].quiz = JSON.parse(JSON.stringify(temp_quiz))
              return next
            })
            setIsLoading(false)
            props.setOpen(false);
          } else if ( delete_result.status === 'error' ) {
            setIsLoading(false)
            props.setOpen(false);
            history.push('/admin/signin')
          }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          {
            isLoading === true ?
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit"/>
              <p>&nbsp;&nbsp; Deleting Question ...</p>
            </Backdrop>
            : <></>
          }
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Delete!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This question will be deleted, are you okay?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
    
  );
}