import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { update_quiz_best_url_by_id } from 'modules/api/market-api'
import history from 'modules/app/components/history'

interface Props {
  selected: boolean
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px !important;
  border: ${(props) => {
    if (props.selected === true) {
      return '2px solid #1976d2'
    } else {
      return 'none'
    }
  }};
`

export default function EditBestUrlDialog(props: any) {
  const [open, setOpen] = React.useState<boolean>(false)
  const [edited_best_url, setEditedBestUrl] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
    if (props.products.length > 0 && props.products.findIndex((ele: any) => ele.active === true) !== -1) {
      if (props.products[props.products.findIndex((ele: any) => ele.active === true)].best_url === undefined) {
        setEditedBestUrl('')
      } else {
        setEditedBestUrl(props.products[props.products.findIndex((ele: any) => ele.active === true)].best_url?.best_url)
      }
    }
  }, [props.open])

  const handleClose = () => {
    setEditedBestUrl('')
    props.setOpen(false)
  }

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)

        let update_result = await update_quiz_best_url_by_id(props.products[props.products.findIndex((ele: any) => ele.active === true)]._id, edited_best_url.trim(), props.products[props.products.findIndex((ele: any) => ele.active === true)].log_file_name)

        if (update_result.status === 'success') {
          props.setProducts((prev: any[]) => {
            let next = [...prev]
            if (next[props.products.findIndex((ele: any) => ele.active === true)].best_url === undefined) {
              next[props.products.findIndex((ele: any) => ele.active === true)] = Object.assign(next[props.products.findIndex((ele: any) => ele.active === true)], {
                best_url: { best_url: edited_best_url.trim(), users: [] },
              })
            } else {
              next[props.products.findIndex((ele: any) => ele.active === true)].best_url.best_url = edited_best_url.trim()
            }

            return next
          })
          setIsLoading(false)
          props.setOpen(false)
        } else if (update_result.status === 'error') {
          setIsLoading(false)
          props.setOpen(false)
          history.push('/admin/signin')
        }
      })()
    } catch (e) {
      // console.log(e)
    }
  }

  const handleBestUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedBestUrl(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {}

  return (
    <>
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
          {isLoading === true ? (
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
              <CircularProgress color="inherit" />
              <p>&nbsp;&nbsp; Saving Data ...</p>
            </Backdrop>
          ) : (
            <></>
          )}
          <DialogTitle>Edit Add first question screener</DialogTitle>
          <DialogContent>
            <DialogContentText>Add first question screener:</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              fullWidth
              value={edited_best_url}
              onChange={handleBestUrlChange}
              onKeyDown={handleKeyDown}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
