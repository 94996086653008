import styled from 'styled-components'

const Home = (props: any) => {
    return (
        <>
         <CustomWrapperInit>
            <div className='top_div'>
                <div className='top_div_main'>
                    <div className='top_div_top'>New Quiz</div>
                    <div className='top_div_bottom'>You can see the new quiz!</div>
                </div>
            </div>
            <div className='bottom_div'>
                
            </div>
        </CustomWrapperInit>
        </>                                                             
    );
};

const CustomWrapperInit = styled.div`
    // min-height: 100vh;
    .top_div {
        background-color: #dff0f74d;;
        height: 690px;
        position: relative;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        .top_div_main {
            padding-left: 24px;
            padding-right: 24px;
            width: 100%;
            margin-left: auto;
            box-sizing: border-box;
            margin-right: auto;
            display: block;
            .top_div_top {
                color: #05426c;
                text-align: center;
                font-family: GT Pressura,sans-serif;
                font-weight: 700;
                line-height: 1.25;
                letter-spacing: 0.01em;
                font-size: 3.75rem;
            }
            .top_div_bottom {
                line-height: 32px;
                font-size: 1.5rem;
                font-family: GT Pressura,sans-serif;
                font-weight: 700;
                line-height: 1.33;
                letter-spacing: 0.01em;
                text-align: center;
                max-width: 500px;
                padding-top: 32px;
                margin: 0 auto;
                box-sizing: inherit;
                .
            }
        }
    }
    .bottom_div {
        
    }
`
export default Home