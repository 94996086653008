import * as React from 'react';
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QuizIcon from '@mui/icons-material/Quiz';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PeopleIcon from '@mui/icons-material/People';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import history from 'modules/app/components/history'

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default function Dashboard() {
    const location = useLocation();
    const [ current_url, setCurrentUrl ] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [app_bar_title, setAppBarTitle] = React.useState('');
    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setCurrentUrl(location.pathname)

        if (location.pathname === '/admin/quiz') {
            setAppBarTitle('Quiz')
        } else if (location.pathname === '/admin/quiz-analyze') {
            setAppBarTitle('Quiz Analyze')
        } else if (location.pathname === '/admin/quiz-analyze-users') {
            setAppBarTitle('Quiz Users Analyze')
        } else if (location.pathname === '/admin/examples-for-finetuning-training') {
            setAppBarTitle('Examples For Fine-Tuning Training')
        }
    }, [location])

    const goto_link = ( e:any, link_name:string) => {
        e.stopPropagation()
    
        if ( link_name === 'data' ) {
          history.push(`/admin/data`)
        } else if ( link_name === 'product' ) {
          history.push(`/admin/product`)
        } else if ( link_name === 'quiz' ) {
          history.push(`/admin/quiz`)
        } else if ( link_name === 'quiz_analyze' ) {
          history.push(`/admin/quiz-analyze`)
        } else if ( link_name === 'quiz_analyze_users' ) {
          history.push(`/admin/quiz-analyze-users`)
        } else if ( link_name === 'examples_for_finetuning_training' ) {
          history.push(`/admin/examples-for-finetuning-training`)
        } 
      }

  return (
    <>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: '#32869d!important',
              minHeight: '48px!important'
            }}
            className='toolbar'
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
                {app_bar_title}
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              minHeight: '48px!important'
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <React.Fragment>
                <ListItemButton onClick={(e:any) => {goto_link(e, 'quiz')}} style={current_url === '/admin/quiz' ? {color: '#0ac5be'} : {}}>
                    <ListItemIcon style={current_url === '/admin/quiz' ? {color: '#32869d'} : {}}>
                        <QuizIcon />
                    </ListItemIcon>
                    <ListItemText primary="Quiz" />
                </ListItemButton>
                <ListItemButton onClick={(e:any) => {goto_link(e, 'quiz_analyze')}} style={current_url === '/admin/quiz-analyze' ? {color: '#0ac5be'} : {}}>
                    <ListItemIcon style={current_url === '/admin/quiz-analyze' ? {color: '#32869d'} : {}}>
                        <AnalyticsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Quiz Analyze" />
                </ListItemButton>
                <ListItemButton onClick={(e:any) => {goto_link(e, 'quiz_analyze_users')}} style={current_url === '/admin/quiz-analyze-users' ? {color: '#0ac5be'} : {}}>
                    <ListItemIcon style={current_url === '/admin/quiz-analyze-users' ? {color: '#32869d'} : {}}>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Quiz Users Analyze" />
                </ListItemButton>
                <ListItemButton onClick={(e:any) => {goto_link(e, 'examples_for_finetuning_training')}} style={current_url === '/admin/examples-for-finetuning-training' ? {color: '#0ac5be'} : {}}>
                    <ListItemIcon style={current_url === '/admin/examples-for-finetuning-training' ? {color: '#32869d'} : {}}>
                        <ModelTrainingIcon />
                    </ListItemIcon>
                    <ListItemText primary="Examples For ft" />
                </ListItemButton>
            </React.Fragment>
          </List>
        </Drawer>
    </>
  );
}