import * as React from 'react';
import { useDropzone } from 'react-dropzone'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { add_new_model, uploadImage, generate_model_via_amazon_with_prouct_url } from 'modules/api/market-api';
import { Grid, IconButton  } from '@mui/material'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import WbAutoIcon from '@mui/icons-material/WbAuto';
import { screenSizes } from 'shared/styles/theme'

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function AddNewModelDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ product_url, setProductUrl ] = React.useState<string>('');
  const [ model_name, setModelName ] = React.useState<string>('');
  const [ iframe_url, setIframeUrl ] = React.useState<string>('');
  const [ description, setDescription ] = React.useState<string>('');
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  const [ files, setFiles ] = React.useState<any>([])
  const [ avatar, setAvatar ] = React.useState<string | undefined>('')
  const [ image_location_flag, setImageLocationFlag ] = React.useState<string>('');

  React.useEffect(() => {
    setOpen(props.open)
    
  }, [props.open])

  React.useEffect(() => {
  }, [props.imgArr])

  React.useEffect(() => {
    if (files.length !== 0) {
      // console.log(files)
      setAvatar(files[0].preview)
    }
  }, [files])

  const handleClose = () => {
    setFiles([])
    setAvatar('')
    setProductUrl('')
    setModelName('')
    setIframeUrl('')
    setDescription('')
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        if ( files.length > 0 && product_url.trim() !== '' && model_name.trim() !== '' && description.trim() !== '' ) {
          setIsLoading(true)

          let image_url = ''

          if ( image_location_flag === 'local' ) {
            let formData: FormData = new FormData()
            formData.append('file', files[0])
            let img_upload_result = await uploadImage(formData)

            if ( img_upload_result.status === 'success' ) {
              // let image_url = img_upload_result.image_url
              image_url = img_upload_result.data.Location
            } else if ( img_upload_result.status === 'error' ) {
              setIsLoading(false)
              props.setOpen(false);
              setFiles([])
              setAvatar('')
              setProductUrl('')
              setModelName('')
              setIframeUrl('')
              setDescription('')
              // history.push('/admin/signin')
            }
          } else if ( image_location_flag === 'amazon') {
            image_url = files[0].preview
          }

          // let formData: FormData = new FormData()
          // formData.append('file', files[0])
          // let img_upload_result = await uploadImage(formData)

          // if ( img_upload_result.status === 'success' ) {
          //   // let image_url = img_upload_result.image_url
          //   let image_url = img_upload_result.data.Location
            let added_result:any = await add_new_model(props.products[props.products.findIndex((ele: any) => ele.active === true)]._id, image_url, product_url, model_name, iframe_url, description)
            if ( added_result.status === 'success' ) {
              let updated_quiz = added_result.addResult        
              props.setProducts((prev: any[]) => {
                let next = [...prev]
                let prev_active_index = next.findIndex(ele => ele.active === true)
  
                
                next[prev_active_index].models = updated_quiz.models
                next[prev_active_index].selected_model_index = updated_quiz.selected_model_index
                next[prev_active_index].result_prompt = updated_quiz.result_prompt
  
                return next
              })

              setFiles([])
              setAvatar('')
              setProductUrl('')
              setModelName('')
              setIframeUrl('')
              setDescription('')
              setIsLoading(false)
              props.setOpen(false);
            } else if ( added_result.status === 'error' ) {
              setIsLoading(false)
              setFiles([])
              setAvatar('')
              setProductUrl('')
              setModelName('')
              setIframeUrl('')
              setDescription('')
              props.setOpen(false);
              // history.push('/admin/signin')
            }
          // } else if ( img_upload_result.status === 'error' ) {
          //   setIsLoading(false)
          //   props.setOpen(false);
          //   setFiles([])
          //   setAvatar('')
          //   setProductUrl('')
          //   setModelName('')
          //   setDescription('')
          //   // history.push('/admin/signin')
          // }
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleProductUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductUrl(e.target.value)
  }
  
  const handleModelNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModelName(e.target.value)
  }
  
  const handleIframeUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIframeUrl(e.target.value)
  }

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }

  const { getRootProps: getRootFileProps, getInputProps: getInputFileProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setImageLocationFlag('local')
      setFiles(
        acceptedFiles.map((file) =>{
          // console.log(file)
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          }
        )
      )
    },
    multiple: false
  })

  const thumbs = files.map((file:any, index:number) => (
    <div className="imageuploadinner" key={index}>
      <img
        src={file.preview}
        onLoad={() => {
          URL.revokeObjectURL(file.preview)
        }}
      />
    </div>
  ))

  const generate_model_via_amazon_api = () => {
    (async () => {
      try {
        setIsLoading(true)
        if ( product_url.trim() !== '') {
          // console.log(product_url)
          let generate_result = await generate_model_via_amazon_with_prouct_url(product_url)

          if( generate_result.status === 'success' ) {
            // console.log(generate_result)
            
            setModelName(generate_result.model_name)
            setDescription(generate_result.description)
            setFiles([{preview: generate_result.image}])
            setImageLocationFlag('amazon')

            setIsLoading(false)
          } else if ( generate_result.status === 'error') {
            setIsLoading(false)
          }
        } else {
          setIsLoading(false)
        }
      } catch (e) {
        // console.log(e)
      }
    })()
  }

  return (
    <>
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'} >
          {
            isLoading === true ? 
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit"/>
              <p>&nbsp;&nbsp; Generating ...</p>
            </Backdrop> : <></>
          }
          <DialogTitle>Add New Model</DialogTitle>
          <DialogContent>
            <CustomDiv1 className={'img_text_container'}>
              <CustomGrid container spacing={0}>
                <CustomGrid item xs={12} md={5}>
                  <CustomDiv1 className={'img_div'}>
                    <Page>
                      <section className="imginner">
                        <aside className="imageupload">
                          {
                          files.length > 0 ? (
                            thumbs
                          ) : 
                          avatar === '' || avatar === undefined ? (
                            <div className="imageuploadinner">
                              {/* <img src={require('assets/cover.png').default} alt="Blank" /> */}
                            </div>
                          ) : (
                            <div className="imageuploadinner">
                              <img src={avatar} alt="Avatar" />
                            </div>
                          )}
                          <div {...getRootFileProps({ className: 'dropzone' })}>
                            <input {...getInputFileProps()} />
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-haspopup="true"
                              className="icon_btn_right"
                            >
                              <InsertPhotoIcon />
                            </IconButton>
                          </div>
                        </aside>
                        <div className="dropzoneOuter">
                        </div>
                        
                      </section>
                    </Page>
                  </CustomDiv1>
                </CustomGrid>
                <CustomGrid item xs={12} md={7} className={'grid_url_model_name'} >
                  <CustomDiv1 className={'text_div'}>
                    <CustomDialogContentText className='cont_text_with_icon'>
                      Product Url:
                      <WbAutoIcon className='auto_icon' onClick={generate_model_via_amazon_api}/>
                    </CustomDialogContentText>
                    <TextField
                      // autoFocus
                      margin="dense"
                      fullWidth
                      value={product_url}
                      onChange={handleProductUrlChange}
                      // onKeyDown={handleKeyDown}
                    />
                    <DialogContentText>
                      Model Name:
                    </DialogContentText>
                    <TextField
                      // autoFocus
                      margin="dense"
                      fullWidth
                      value={model_name}
                      onChange={handleModelNameChange}
                      // onKeyDown={handleKeyDown}
                    />
                    <DialogContentText>
                      Iframe Url:
                    </DialogContentText>
                    <TextField
                      // autoFocus
                      margin="dense"
                      fullWidth
                      value={iframe_url}
                      onChange={handleIframeUrlChange}
                      // onKeyDown={handleKeyDown}
                    />
                  </CustomDiv1>
                </CustomGrid>
              </CustomGrid>
            </CustomDiv1>
            <DialogContentText>
              Description:
            </DialogContentText>
            <TextField
              // autoFocus
              margin="dense"
              fullWidth
              multiline
              rows={10}
              maxRows={10}
              value={description}
              onChange={handleDescriptionChange}
              // onKeyDown={handleKeyDown}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
    
  );
}

const CustomDialogContentText = styled(DialogContentText)`
  &.cont_text_with_icon {
    display: flex;
    justify-content: space-between;
    .auto_icon {
      color: #0058ff75!important;
      cursor: pointer!important;
    }
    .auto_icon:hover {
      color: #0058ff!important;
    }
  }
`

const CustomDiv1 = styled.div`
  &.img_text_container {
    display: flex!important;
    margin-bottom: 20px;
    .img_div {

    }
    .text_div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  &.num_quiz_div {
    display: flex!important;
    flex-direction: row!important;
    align-items: center!important;
    input {
      margin-left: 10px;
      padding: 4px;
      font-size: 18px;
      line-height: 30px;
      border: 1px solid #c4c4c4!important;
      border-radius: 5px!important;
      width:50px!important;
    }
    input:hover {
      border-color: black!important;
    }
    input:focus-visible {
      border: 2px solid #1976d2!important;
      outline: none!important;
    }
    
  }
`

const Page = styled.div`
  color: #fff;
  display: flex;
  justify-content: Center;
  align-items: Center;
  
  .imginner {
    border: 1px solid rgba(0,0,0,0.05)!important;
    border-radius: 24px!important;
  }
  .imageupload {
    display: flex;
    justify-content: Center;
    border-radius: 10px;
    position: relative;
    .imageuploadinner {
      position: relative;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 300px;
        height: 300px;
      }
      width: 250px;
      height: 250px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        border-radius: 24px;
        object-fit: contain;
      }
      .icon_btn {
        color: rgb(120, 115, 115);
        position: absolute;
        top: 30%;
        left: 30%;
        &:hover {
          background-color: white!important;
          color: black;
        }
      }
    }
    .icon_btn_right {
      color: rgb(120, 115, 115);
      position: absolute;
      top: 2%;
      right: 2%;
      &:hover {
        background-color: white!important;
        color: #049cf7;
      }
    }
  }
  .dropzoneOuter {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    max-width: 200px;
    margin: auto;
  }
`

const CustomGrid = styled(Grid)`
  &.grid_url_model_name {
    @media (min-width: ${screenSizes.mediaM}px) {
        padding: 0 0 0 10px!important;
    }
    padding: 10px 0 0 0!important;
  }
`