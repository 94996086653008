import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FooterBgImg, BoldSpan, CustomSpan } from './style'
import styled from 'styled-components'
import { Grid, FormGroup, Button } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import history from 'modules/app/components/history'

const Footer = (props: any) => {
    const location = useLocation();

    const [ current_url, setCurrentUrl ] = useState('')

    useEffect(() => {
      setCurrentUrl(location.pathname)
    }, [location])
  
    
    return (
      <>
        {
          current_url === '/admin' ? <></>
          :
          current_url === '/admin/product' ? <></>
          :
          current_url === '/admin/signin' ? <></>
          :
          current_url === '/quiz/list/all' ? <></>
          :
          current_url.startsWith('/quiz/') === true ? <></>
          :
          current_url === '/admin/quiz' ? <></>
          :
          current_url === '/admin/quiz-analyze' ? <></>
          :
          current_url === '/admin/quiz-analyze-users' ? <></>
          :
          current_url === '/admin/data' ? <></>
          :
          <>
            {/* <FooterContainer>
              <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
                  <CustomGrid container item xs={12} sm={12} md={6} className="grid_footer_left ">
                    <div className="footer_logo">
                      <FooterBgImg src={require('assets/logo_avatar3.png').default} alt="" />
                      &nbsp;<CustomSpan ><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</CustomSpan>
                    </div>
                    <CustomGrid container className="links_div">
                      <CustomGrid container item xs={12} sm={12} md={6} className="p_0">
                        <CustomGrid item xs={6} className="p_0"><span className="cursor_pointer">Categories</span></CustomGrid>
                        <CustomGrid item xs={6} className="p_0"><span className="cursor_pointer">About Us</span></CustomGrid>
                      </CustomGrid>
                      <CustomGrid container item xs={12} sm={12} md={6} className="p_0">
                        <CustomGrid item xs={6} className="p_0"><span className="cursor_pointer" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); history.push(`/s_doc/terms_of_use`)}}>Terms of Use</span></CustomGrid>
                        <CustomGrid item xs={6} className="p_0"><span className="cursor_pointer" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); history.push(`/s_doc/privacy_policy`)}}>Privacy Policy</span></CustomGrid>
                      </CustomGrid>
                      
                    </CustomGrid>
                    <div className="footer_left_des">
                      <p>Topbestsellers.org is a data-driven product research and ranking tool. Our system analyzes massive amounts of product data to find the best fit for you.</p>
                    </div>
                  </CustomGrid>
                  <CustomGrid item xs={12} sm={12} md={6} className="grid-flex-item-end grid_footer_right" >
                    <span className="footer_right_title">Sign Up and Stay Informed!</span>
                    <div className="footer_right_des1">
                      <p>Get product information from experts delivered straight to your inbox by clicking Subscribe below.</p>
                    </div>
                    <div className="mail_div">
                      <FormGroup row>
                        <input type='email' placeholder="Enter email address" required/>
                        <CustomButton variant="contained" disableElevation>
                          Subscribe
                        </CustomButton>
                      </FormGroup>
                    </div>
                    <div className="footer_right_des2">
                      <p>Topbestsellers.org generates product rankings at its discretion using proprietary methods. As an Amazon Associate, we earn from qualifying purchases. Some of the content on this site comes from third parties. This content is provided ‘as is’ and is subject to change or removal at any time.</p>
                    </div>
                  </CustomGrid>
              </CustomGrid>
          </FooterContainer> */}
          </>
        }
      </>
        
    );
};


const CustomButton = styled(Button)`
  background-color: #f2a842!important;
  border: 1px solid gray;
  border-bottom-left-radius: 0px!important;
  border-top-left-radius: 0px!important;
  border-bottom-right-radius: 5px!important;
  border-top-right-radius: 5px!important;
  text-transform: none!important;
  font-size: 16px!important;
`

const FooterContainer = styled.div`
//   @media (max-width: ${screenSizes.mediaM}px) {
//     display: none;
//   }
`
const CustomGrid = styled(Grid)`
  background-color: #1e947e!important;
  padding: 8px 20px;
  margin: 0px!important;

  .grid-flex-item-end {
    display: flex;
    justify-content: start;
    
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid_footer_left {
    display: flex;
    flex-direction: column;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }

    .footer_logo{
      display: flex;
      align-items: center;
      font-size: 25px;
      cursor: pointer;
    }

    .links_div{
      display: flex!important;
      align-items: center!important;
      flex-direction: row!important;
      font-size: 16px!important;
      font-weight: 600!important;
      color: white!important;
      margin: 5px 0px!important;
      @media (min-width: ${screenSizes.mediaS}px) {
        margin: 10px 0px!important;
      }
      padding: 0px!important;
      .cursor_pointer {
        cursor: pointer!important;
      }
      .links_margin {
        // margin-left: 25px!important;
      }
      .p_0 {
        padding: 10px 0px!important;
      }
    }

    .footer_left_des {
      color: white;
      font-size: 16px;
    }
    p {
      margin: 0px!important;
    }
  }

  .grid_footer_right {
    display: flex;
    flex-direction: column;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }

    .footer_right_title {
      color: white!important;
      font-weight: 700!important;
      font-size: 18px;
    }

    .footer_right_des1 {
      font-size: 16px;
      color: white;
      margin: 10px 0px;
      p {
        margin: 0px!important;
      }
    }

    .footer_right_des2{
      font-size: 14px;
      color: white;
      margin: 10px 0px;
      p {
        margin: 0px!important;
      }
    }

    .mail_div{
      width: 100%;
      // background: green!important;
      input:focus,
      select:focus,
      textarea:focus,
      button:focus {
          outline: none;
      }
      input {
        border: 1px solid white;
        border-bottom-left-radius: 5px!important;
        border-top-left-radius: 5px!important;
        font-size: 18px!important;
        padding-left: 10px;
        width: 50%!important;
      }
    }
   
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
`

const mapStateToProps = (state: any) => ({
    
})


export default connect(mapStateToProps)(Footer);