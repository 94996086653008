import { useState, useEffect } from 'react'
import styled from 'styled-components'
import jwt_decode from 'jwt-decode'
import { Grid, Box } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import history from 'modules/app/components/history'
import TabPanelDiv from './sub_components/TabPanelDiv'
import AdminHeader from 'shared/AdminHeader'
import Test from 'modules/test'
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

const Admin_Quiz = (props: any) => {
    useEffect(() => {
      (async () => {
        if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
            const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
            if ( decoded.id == localStorage.getItem('userDbId') ) {

            } else {
                history.push(`/admin/signin`)
            }
        } else {
            history.push(`/admin/signin`)
        }
      })()
        
    }, [])

    return (
        <>
          <Box sx={{ display: 'flex' }}>
          {/* <AdminHeader /> */}
            <Test />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Toolbar sx={{minHeight: '48px!important'}}/>
              <Container sx={{ mt: 0, mb: 0, pl: '0!important', pr: '0!important', maxWidth: '100%!important' }}>
                <CustomGrid container spacing={0} className={"pt-0"}>
                  <CustomGrid item xs={12}  className={"pt-0"}>
                    <Box sx={{ typography: 'body1' }}>
                      <TabPanelDiv list_name={""} />
                    </Box>
                  </CustomGrid>
                </CustomGrid>
              </Container>
            </Box>
          </Box>
        </>
    );
};

const CustomGrid = styled(Grid)`
  background-color: white!important;
  padding: 2px;
  margin: 0px!important;

  .pt-0 {
    padding-top: 0px!important;
  }
`
export default Admin_Quiz