/* global chrome */

import * as React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Button, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check'
import { screenSizes } from 'shared/styles/theme'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './review_pan.css'
import { update_name_email_phone_of_user_info_on_quiz } from 'modules/api/market-api'


export default function Review_pan(props:any) {

  const [ first_name, setFirstName ] = React.useState<string>('')
  const [ last_name, setLastName ] = React.useState<string>('')
  const [ work_mail, setWorkMail ] = React.useState<string>('')
  const [ company_name, setCompanyName ] = React.useState<string>('')
  const [ phone_number, setPhoneNumber ] = React.useState<string>('')

  const [ f_name_is_empty, setFNameIsEmpty ] = React.useState<boolean>(false)
  const [ l_name_is_empty, setLNameIsEmpty ] = React.useState<boolean>(false)
  const [ mail_is_empty, setMailIsEmpty ] = React.useState<boolean>(false)
  const [ c_name_is_empty, setCompanyNameIsEmpty ] = React.useState<boolean>(false)

  const [ show_input_fields_div, setShowInputFieldsDiv ] = React.useState<boolean>(true)
  const [ show_thanks_div, setShowThanksDiv ] = React.useState<boolean>(false)
  const [ show_circle_progress_div, setShowCircleProgressDiv ] = React.useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      
    })()
  }, [])


  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value)
  }
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value)
  }
  const handleWorkMailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkMail(e.target.value)
  }
  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value)
  }
  const handlePhoneNumberChange = (phone:any) => {
    setPhoneNumber(phone)
  }


  const click_next_btn = () => {
    ;(async () => {
      // console.log("click next button ... ")

      if (first_name.trim() === '') {
        setFNameIsEmpty(true)
      }
      if (last_name.trim() === '') {
        setLNameIsEmpty(true)
      }
      if (work_mail.trim() === '') {
        setMailIsEmpty(true)
      }
      if (phone_number.trim() === '') {
        setCompanyNameIsEmpty(true)
      }
      // if (company_name.trim() === '') {
      //   setCompanyNameIsEmpty(true)
      // }

      if (first_name.trim() !== '' && last_name.trim() !== '' && work_mail.trim() !== '' && phone_number.trim() !== '') {
      // if (first_name.trim() !== '' && last_name.trim() !== '' && work_mail.trim() !== '' && company_name.trim() !== '') {
        // console.log(phone_number)
        setShowInputFieldsDiv(false)

        setShowCircleProgressDiv(true)

        await update_name_email_phone_of_user_info_on_quiz(props.quiz_id, first_name, last_name, work_mail, phone_number, props.log_file_name)
        await new Promise((r) => setTimeout(r, 2000))

        setShowCircleProgressDiv(false)

        setShowThanksDiv(true)
      }
    })()
    
  }

  return (
    <>
     <CustomGrid container spacing={0} className="review_container_grid">
        <CustomGrid item sm={12}>
          <div className="rvp_talk_advisor_container">
            <div className="rvp_talk_advisor_flex">
              <div className="rvp_advisor_photo">
                <div className="rvp_ssr_talk_advisor_photo">
                  <img 
                    src="https://cdn.prod.website-files.com/5dbc34a79684004f6e7a6c30/65c62d4fd0a98ca655828b84_zach.jpg" 
                    loading="lazy" 
                    alt="" 
                    className="rvp_ssr_image_cover_img" 
                  />
                </div>
                <div className="rvp_ssr_talk_advisor_photo_shadow">
                </div>
              </div>

              <div className="rvp_advisor_title">
                <h1 className="rvp_ssr_h1">Need Help? Talk to an Expert for Free!</h1>
              </div>

              <div className="rvp_advisor_info">
                <div className="rvp_ssr_talk_advisor_checklist">
                  <div className="rvp_ssr_talk_advisor_checkitem">Get 1:1 help from a {props.product_name} Advisor</div>
                  <div className="rvp_ssr_talk_advisor_checkitem">Save time with a personalized recommendation</div>
                  <div className="rvp_ssr_talk_advisor_checkitem">Save up to 20% with our pricing insights</div></div>
              </div>
            </div>

            {/* <p className="rvp_ssr_talk_advisor_p">Tell us more about your company &amp; an HR Software Advisor will help you find the right software</p> */}
          </div>
        </CustomGrid>

        {
          show_input_fields_div === true ?
          <CustomGrid item sm={12}>
            <div className='rvp_content_container'>
              <div className='rvp_question_container'>
                <div className="rvp_question_col">
                    <div className='rvp_col_wrapper'>
                      <h2 className='rvp_col_ques_title'>How should we get in touch?</h2>
                    </div>
                </div>
                <div className="rvp_question_col">
                    <div className='rvp_col_wrapper'>
                      <h5 className='rvp_col_sub_str'>One of our {props.product_name} experts will give you a ring to help you find the right fit.</h5>
                    </div>
                </div>
                <div className="rvp_question_col">
                
                    <div className='rvp_col_wrapper'>
                      <div className="rvp_pl_5_mr_1">
                        <div className='rvp_field_wrapper'>
                          <div className='rvp_flex_col'>
                            <div className='rvp_col_label_div'>
                              <p style={{margin: 0}}>
                                First Name
                                <span 
                                  className="fillout-required-asterisk" 
                                  aria-label="Required question" 
                                  title="Required question" 
                                  style={{color: '#f87171'}}
                                > *</span>
                              </p>
                            </div>
                            <div className='rvp_input_field_div'>
                              <input 
                                data-cy="input-component" 
                                type="text" 
                                className="rvp_input_field_text" 
                                placeholder="" 
                                aria-label="First Name" 
                                value={first_name}
                                onChange={handleFirstNameChange}
                              />
                            </div>
                            {
                              f_name_is_empty === false ? 
                              <></> :
                              <div 
                                className="flex items-center mt-2 fillout-error-validation-message" 
                                role="status" aria-live="polite" 
                                style={{
                                  color: 'rgb(248, 113, 113)', 
                                  fontSize: '0.875rem', 
                                  fontWeight: 400, 
                                  lineHeight: '1.3em',
                                  display: 'flex',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                  <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 20 20" 
                                    fill="currentColor" 
                                    aria-hidden="true" 
                                    className="rvp_required_svg">
                                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
                                  </svg>
                                  &nbsp;Field is required
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="rvp_pl_5_mr_1">
                        <div className='rvp_field_wrapper'>
                        <div className='rvp_flex_col'>
                            <div className='rvp_col_label_div'>
                              <p style={{margin: 0}}>
                                Last Name
                                <span 
                                  className="fillout-required-asterisk" 
                                  aria-label="Required question" 
                                  title="Required question" 
                                  style={{color: '#f87171'}}
                                > *</span>
                              </p>
                            </div>
                            <div className='rvp_input_field_div'>
                              <input 
                                data-cy="input-component" 
                                type="text" 
                                className="rvp_input_field_text" 
                                placeholder="" 
                                aria-label="First Name" 
                                value={last_name}
                                onChange={handleLastNameChange}
                              />
                            </div>
                            {
                              l_name_is_empty === false ? 
                              <></> :
                              <div 
                                className="flex items-center mt-2 fillout-error-validation-message" 
                                role="status" aria-live="polite" 
                                style={{
                                  color: 'rgb(248, 113, 113)', 
                                  fontSize: '0.875rem', 
                                  fontWeight: 400, 
                                  lineHeight: '1.3em',
                                  display: 'flex',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                  <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 20 20" 
                                    fill="currentColor" 
                                    aria-hidden="true" 
                                    className="rvp_required_svg">
                                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
                                  </svg>
                                  &nbsp;Field is required
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>

                <div className="rvp_question_col">
                
                    <div className='rvp_col_wrapper'>
                      <div className="rvp_pl_5_mr_1">
                        <div className='rvp_field_wrapper'>
                          <div className='rvp_flex_col'>
                            <div className='rvp_col_label_div'>
                              <p style={{margin: 0}}>
                                Email
                                <span 
                                  className="fillout-required-asterisk" 
                                  aria-label="Required question" 
                                  title="Required question" 
                                  style={{color: '#f87171'}}
                                > *</span>
                              </p>
                            </div>
                            <div className='rvp_input_field_div'>
                              <div className="rvp_absolute_mail_svg">
                                <svg 
                                  xmlns="http://www.w3.org/2000/svg" 
                                  viewBox="0 0 20 20" 
                                  fill="currentColor" 
                                  aria-hidden="true" 
                                  className="text-gray-400" 
                                  style={{color: 'rgba(75, 85, 99, 0.7)', height: '20px', width: '20px'}}
                                >
                                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                </svg>
                              </div>
                              <input 
                                data-cy="input-component" 
                                type="text" 
                                className="rvp_input_field_text_email" 
                                placeholder="name@company.com" 
                                aria-label="First Name" 
                                value={work_mail}
                                onChange={handleWorkMailChange}
                              />
                            </div>
                            {
                              mail_is_empty === false ? 
                              <></> :
                              <div 
                                className="flex items-center mt-2 fillout-error-validation-message" 
                                role="status" aria-live="polite" 
                                style={{
                                  color: 'rgb(248, 113, 113)', 
                                  fontSize: '0.875rem', 
                                  fontWeight: 400, 
                                  lineHeight: '1.3em',
                                  display: 'flex',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                  <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 20 20" 
                                    fill="currentColor" 
                                    aria-hidden="true" 
                                    className="rvp_required_svg">
                                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
                                  </svg>
                                  &nbsp;Field is required
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="rvp_pl_5_mr_1">
                        <div className='rvp_field_wrapper'>
                        <div className='rvp_flex_col'>
                            <div className='rvp_col_label_div'>
                              <p style={{margin: 0}}>
                                Phone Number
                                <span 
                                  className="fillout-required-asterisk" 
                                  aria-label="Required question" 
                                  title="Required question" 
                                  style={{color: '#f87171'}}
                                > *</span>
                              </p>
                            </div>
                            <div className='rvp_input_field_div'>
                              {/* <input 
                                data-cy="input-component" 
                                type="text" 
                                className="rvp_input_field_text" 
                                placeholder="" 
                                aria-label="First Name" 
                                value={company_name}
                                onChange={handleCompanyNameChange}
                              /> */}
                              <PhoneInput
                                  country={"us"}
                                  value={phone_number}
                                  onChange={(phone:any) => {handlePhoneNumberChange(phone)}}
                              />
                            </div>
                            {
                              c_name_is_empty === false ? 
                              <></> :
                              <div 
                                className="flex items-center mt-2 fillout-error-validation-message" 
                                role="status" aria-live="polite" 
                                style={{
                                  color: 'rgb(248, 113, 113)', 
                                  fontSize: '0.875rem', 
                                  fontWeight: 400, 
                                  lineHeight: '1.3em',
                                  display: 'flex',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                  <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 20 20" 
                                    fill="currentColor" 
                                    aria-hidden="true" 
                                    className="rvp_required_svg">
                                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
                                  </svg>
                                  &nbsp;Field is required
                              </div>
                            }
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>
                
                <div className="rvp_question_col">
                    <div className='rvp_col_wrapper'>
                      <div className='rvp_btn_div'>
                        <div className='rvp_btn_flex_div'>
                          <button 
                            data-cy="button-component" 
                            type="button" 
                            className="rvp_btn_btn" 
                            style={{
                              background: 'rgb(59, 130, 246)', 
                              color: 'white', 
                              fontSize: '1rem', 
                              lineHeight: '1.4em', 
                              fontWeight: 500
                              }}

                            onClick={() => {click_next_btn()}}
                          >
                            <span className="rvp_max_w_full">
                              <div 
                                className="rvp_ant_space" 
                                style={{gap: '8px'}}
                              >
                                <div className="rvp_ant_space_item">
                                  <div>Get Free Advice</div>
                                </div>
                              </div>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </CustomGrid>
          : <></>
        }

        
        
        {
          show_thanks_div === true ?
          <CustomGrid item sm={12}>
            <div className='rvp_content_container'>
              <div className='rvp_question_container'>
                <div className='rvp_thanks_wrapper'>
                  <div className='rvp_thanks_container'>
                    <div className='rvp_thanks_check_div_wrapper'>
                        <div className='rvp_thanks_check_container'>
                          <svg width="24px" height="24px" viewBox="0 -1.5 14 14" id="meteor-icon-kit__solid-checkmark-xs" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.06066 4.9393C2.47487 4.3536 1.52513 4.3536 0.93934 4.9393C0.35355 5.5251 0.35355 6.4749 0.93934 7.0607L3.93934 10.0607C4.52513 10.6464 5.4749 10.6464 6.0607 10.0607L13.0607 3.0607C13.6464 2.47487 13.6464 1.52513 13.0607 0.93934C12.4749 0.35355 11.5251 0.35355 10.9393 0.93934L5 6.8787L3.06066 4.9393z" fill="#3b82f6"/></svg>
                        </div>
                    </div>

                    <div className='rvp_thanks_str_div_wrapper'>
                        <div className='rvp_thanks_str_title_div'>
                          <div className='rvp_thanks_str_title_wrapper'>
                            <p className='rvp_thanks_str_title_p'>Thank you</p>
                          </div>
                        </div>

                        <div className='rvp_thanks_str_substr_div'>
                          <p className='rvp_thanks_str_substr_p'>A {props.product_name} advisor will reach out to you shortly!</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomGrid>
          : <></>
        }

        {
          show_circle_progress_div === true ?
          <CustomGrid item sm={12}>
            <div className='rvp_content_container'>
              <div className='rvp_question_container'>
                <div className='rvp_circle_progress_div_wrapper'>
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                </div>
              </div>
            </div>
          </CustomGrid>
          : <></>
        }
        

      </CustomGrid>
    </>
  )
}


const CustomGrid = styled(Grid)`
  &.review_container_grid {
    max-width: 760px;
    margin: 20px auto;
  }



`

